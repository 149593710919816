import React, {ChangeEvent, useEffect, useRef} from "react";
import styles from "./Toolbar.module.css";
import {useTranslation} from "react-i18next";
import iconBack from "systemicons/back.svg";
import iconBackIOS from "systemicons/back_ios.svg";
import iconClose from "systemicons/close.svg";
import iconSearch from "systemicons/search.svg";
import iconHome from "systemicons/home.svg";
import {useHistory} from "react-router";
import {Capacitor} from "@capacitor/core";

type NavButtonType = "back" | "close";
type ActionButtonType = "search" | "home";

interface ToolbarProps {
	//Top left button
	button: NavButtonType;
	
	//Toolbar title
	title: string;
	
	//Called when the top left button is clicked
	onClickUp: () => void;
	
	//Shows a search icon, and passes search text to onSearch
	actionButton?: ActionButtonType;
	searchPlaceholder?: string;
	searchQuery?: string,
	//Listener for search text updates, "undefined" if not searching
	onSearch?: (searchQuery?: string) => void;
}

//A toolbar at the top of the screen that handles site navigation and search
export default function Toolbar(props: ToolbarProps) {
	//const [searchOpen, setSearchOpen] = useState(false);
	
	function updateSearch(query?: string) {
		if(props.onSearch) props.onSearch(query);
	}
	
	function openSearch() {
		//setSearchOpen(true);
		
		//Setting a default search query
		updateSearch("");
	}
	
	function closeSearch() {
		//setSearchOpen(false);
		
		//Clearing the search query
		updateSearch(undefined);
	}
	
	return (
		<div className={styles.background}>
			<div className={`${styles.container} content`}>
				{props.searchQuery !== undefined ?
					<SearchToolbar placeholder={props.searchPlaceholder} onClose={closeSearch} text={props.searchQuery} onText={updateSearch} /> :
					<NavigationToolbar button={props.button} onClickUp={props.onClickUp} title={props.title} actionType={props.actionButton} onSearch={openSearch} />
				}
			</div>
		</div>
	);
}

//A toolbar that only displays search
export function SearchOnlyToolbar(props: {searchPlaceholder?: string, searchQuery: string | undefined, onSearch: (searchQuery: string) => void, onClose: () => void}) {
	return (
		<div className={styles.background}>
			<div className={`${styles.container} content`}>
				<SearchToolbar placeholder={props.searchPlaceholder} onClose={props.onClose} text={props.searchQuery} onText={props.onSearch} />
			</div>
		</div>
	);
}

function NavigationToolbar(props: {button: NavButtonType, onClickUp: () => void, title: string, actionType?: ActionButtonType, onSearch?: () => void}) {
	const {t} = useTranslation();
	const history = useHistory();
	
	function returnHome() {
		history.push("/");
	}
	
	return (
		<React.Fragment>
			<button className={styles.button} onClick={props.onClickUp}>
				<img
					src={props.button === "back" ? (Capacitor.platform === "ios" ? iconBackIOS : iconBack) : iconClose}
					alt={props.button === "back" ? t("action.back") : t("action.close")} />
			</button>
			
			<h3 className={styles.title}>{props.title}</h3>
			
			{props.actionType === "search" && <button className={styles.button} onClick={props.onSearch}>
				<img
					src={iconSearch}
					alt={t("action.search")} />
			</button>}
			{props.actionType === "home" && <button className={styles.button} onClick={returnHome}>
				<img
					src={iconHome}
					alt={t("action.home")} />
			</button>}
			{!props.actionType && <div className={styles.buttonPlaceholder} />}
		</React.Fragment>
	);
}

function SearchToolbar(props: {placeholder?: string, onClose: () => void, text?: string, onText: (searchQuery: string) => void}) {
	const {t} = useTranslation();
	
	//Focus the input field on mount
	const inputEl = useRef<HTMLInputElement>(null);
	useEffect(() => inputEl.current!.focus());
	
	function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const text = event.target.value;
		props.onText(text);
	}
	
	return (
		<React.Fragment>
			<input className={styles.searchField} ref={inputEl} type="text" placeholder={props.placeholder} value={props.text ?? ""} onChange={handleInputChange} />
			<button className={styles.button} onClick={props.onClose}>
				<img
					src={iconClose}
					alt={t("action.close")} />
			</button>
		</React.Fragment>
	);
}