import React, {useEffect, useState} from "react";
import styles from "./Search.module.css";

import {SearchOnlyToolbar} from "../Toolbar";
import StateHelper, {DataState} from "../StateHelper";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {getRecordEntries} from "../../util/dataUtils";
import RecordEntry from "../../data/recordEntry";
import SearchEntry from "./SearchEntry";
import {Search as JsSearch, SimpleTokenizer, StemmingTokenizer, StopWordsTokenizer} from "js-search";
import stemmer from "stemmer";
import {CategoryData, findCategory} from "../../util/categoryDefinitions";
import removeMd from "remove-markdown";

//A record entry with extra search metadata
export interface SearchRecordEntry {
	entry: RecordEntry;
	category: CategoryData;
	path: string[];
	displayDetail: string; //Detail text stripped of HTML
}

//State of Search
//An array of search record entries if available, otherwise "loading" or "failed"
type EntryValue = JsSearch | DataState;

//A screen that searches the entire site
export default function Search() {
	const {t} = useTranslation();
	const history = useHistory();
	
	const [searchText, setSearchText] = useState<string | undefined>(undefined);
	const [searchEntries, setSearchEntries] = useState<EntryValue>(DataState.Loading);
	useEffect(() => {
		//Reverting the state to loading
		setSearchEntries(DataState.Loading);
		
		//Request data
		const recordEntriesPromise = getRecordEntries();
		if(recordEntriesPromise) {
			recordEntriesPromise.then((result) => {
				//Flattening the tree
				const flattenedArray = Array.from(result, ([categoryID, entries]) => {
					//Getting the associated category
					const category = findCategory(categoryID)!;
					
					//Flatten each data code grouping
					const flattenedArray: SearchRecordEntry[] = [];
					flattenRecordEntries(entries, flattenedArray, category, [t(category.i18nName)]);
					return flattenedArray;
				}).flat(); //Then flatten all of the result arrays into a single array
				
				//Indexing the items
				const search = new JsSearch(["entry", "id"]);
				
				//Set up a stemming tokenizer with a stop words tokenizer
				search.tokenizer = new StemmingTokenizer(stemmer, new StopWordsTokenizer(new SimpleTokenizer()));
				
				search.addIndex(["entry", "title"]); //Search the title
				search.addIndex("path"); //Search the path
				search.addIndex("displayDetail"); //Search the stripped content
				
				search.addDocuments(flattenedArray);
				
				setSearchEntries(search);
			}).catch(() => {
				setSearchEntries(DataState.Failed);
			})
		} else {
			//The request failed before we even asked for the result
			setSearchEntries(DataState.Failed);
		}
		
		//Restoring search
		if(window.history.state && typeof window.history.state === "string") setSearchText(window.history.state);
	}, [t]);
	function updateSearch(searchQuery: string) {
		setSearchText(searchQuery);
		window.history.replaceState(searchQuery, "");
	}
	
	return (
		<div>
			<SearchOnlyToolbar
				searchPlaceholder={t("action.searchEverywhere")}
				onClose={() => history.push("/")}
				searchQuery={searchText}
				onSearch={updateSearch} />
			<StateHelper state={searchEntries} onReady={(search) => {
				let bodyElement: JSX.Element;
				
				if(!searchText) {
					//The user hasn't inputted any text yet
					bodyElement = <p className={styles.labelCenter}>{t("message.startSearching")}</p>;
				} else {
					//Filtering the entries
					const filteredEntries = search.search(searchText) as SearchRecordEntry[];
					//const filteredEntries = filterEntries(pathedEntries, searchText?.trim()?.toLowerCase());
					if(filteredEntries.length > 0) {
						bodyElement = (
							<React.Fragment>
								{filteredEntries.map((pathedEntry) => <SearchEntry key={pathedEntry.entry.id} pathedEntry={pathedEntry} />)}
							</React.Fragment>
						);
					} else {
						bodyElement = <p className={styles.labelCenter}>{t("message.noResults")}</p>;
					}
				}
				
				return (
					<div className="content content--padding">
						<div className={`card ${styles.list}`}>
							{bodyElement}
						</div>
					</div>
				);
			}} />
		</div>
	);
}

//Extract end-level text entries from a tree of entries, and build their path data
function flattenRecordEntries(input: RecordEntry[], target: SearchRecordEntry[], category: CategoryData, path: string[] = []) {
	for(const entry of input) {
		//If this is a text entry, add it right to the target array
		if(typeof entry.detail === "string") {
			target.push({
				entry: entry,
				category: category,
				path: path,
				displayDetail: removeMd(entry.detail as string)
			});
		} else {
			//Recursively pull up the children of this group entry
			flattenRecordEntries(entry.detail, target, category, path.concat(entry.title));
		}
	}
}