import React from "react";
import styles from "./Spinner.module.css";

//Loading spinner
export default function Spinner() {
	return (
		<div className={styles.spinner}>
			<div className={styles.doubleBounce1} />
			<div className={styles.doubleBounce2} />
		</div>
	);
}