import React from "react";
import styles from "./HomeToolbar.module.css";
import logo from "icons/premergency.svg";
import info from "systemicons/info.svg";
import iconSearch from "../../systemicons/search.svg";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function HomeToolbar() {
	return (
		<div className={styles.background}>
			<div className="content">
				<div className={styles.container}>
					<div className={styles.actionPlaceholder} />
					<img className={styles.logo} src={logo} alt="" />
					<Link className={styles.actionButton} to="/about">
						<img src={info} alt="" />
					</Link>
				</div>
				
				<Search />
			</div>
		</div>
	);
}

function Search() {
	const {t} = useTranslation();
	
	return (
		<div className={`${styles.searchContainer}`}>
			<Link to="/search" className={`card ${styles.search}`}>
				<img className="invertableLight" src={iconSearch} alt={t("action.search")} />
				<span>{t("action.searchEverywhere")}</span>
			</Link>
		</div>
	);
}