import React, {useEffect, useState} from "react";
import styles from "./EntryViewer.module.css";

import RecordEntry, {RecordEntryAttachment} from "../../data/recordEntry";
import {useHistory} from "react-router";
import Toolbar from "../Toolbar";
import {cmsBaseURL, getRecordEntries} from "../../util/dataUtils";
import StateHelper, {DataState} from "../StateHelper";
import {useTranslation} from "react-i18next";

import iconLaunch from "systemicons/launch.svg";
import Markdown from "markdown-to-jsx";
import {CategoryData} from "../../util/categoryDefinitions";

//Renders a single entry
export default function EntryViewer(props: {category: CategoryData, entryID: number}) {
	const history = useHistory();
	
	const [entry, setEntry] = useState<RecordEntry | DataState>(DataState.Loading);
	useEffect(() => {
		//Reverting the state to loading
		setEntry(DataState.Loading);
		
		//Request data
		const recordEntriesPromise = getRecordEntries();
		if(recordEntriesPromise) {
			recordEntriesPromise.then((result) => {
				const entryArray = result.get(props.category.id);
				if(entryArray) {
					setEntry(findEntryRecursive(entryArray, props.entryID) ?? DataState.Failed);
				} else {
					setEntry(DataState.Failed);
				}
			}).catch(() => {
				setEntry(DataState.Failed);
			});
		} else {
			//The request failed before we even asked for the result
			setEntry(DataState.Failed);
		}
	}, [props.category.id, props.entryID]);
	
	//We can't render entries that are groups, as they don't have any content
	if(typeof entry === "object" && typeof entry.detail !== "string") {
		console.warn(`Tried to open viewer to group entry! (ID ${entry.id}, title ${entry.title}`);
		return null;
	}
	
	return (
		<div>
			<Toolbar button="back" title={typeof entry === "object" ? entry.title : ""} onClickUp={() => history.length > 2 ? history.goBack() : history.push(`/${props.category.id}`)} actionButton="home" />
			<StateHelper state={entry} onReady={(entry) => (
				<div className="content content--padding">
					<div className={`card ${styles.main}`}>
						{entry.attachments?.map((attachment, index) => <Attachment className={index > 0 ? styles.attachmentMargin : undefined} key={attachment.id} attachment={attachment} />)}
						<Markdown
							options={{
								createElement(type, props, children) {
									if(type === "img") {
										const imageProps = props as React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
										if(imageProps.src) imageProps.src = cmsBaseURL + imageProps.src;
									}
									return React.createElement(type, props, children);
								}
							}}
						>{entry.detail as string}</Markdown>
					</div>
				</div>
			)} />
		</div>
	);
}

function Attachment(props: {className?: string, attachment: RecordEntryAttachment}) {
	if(props.attachment.mime.startsWith("image/")) {
		return <img className={(props.className ? props.className + " " : "") + styles.image} src={props.attachment.url} alt={props.attachment.alt} />;
	} else {
		return <AttachmentLink className={props.className} attachment={props.attachment} />
	}
}

//Renders a clickable attachment
function AttachmentLink(props: {className?: string, attachment: RecordEntryAttachment}) {
	const {t} = useTranslation();
	
	return (
		<a className={(props.className ? props.className + " " : "") + styles.attachmentLink} href={props.attachment.url}>
			<span>{t(props.attachment.name)}</span>
			<img className={styles.icon} src={iconLaunch} alt="" />
		</a>
	);
}

//Searches recursively through the provided entry array to find a matching entry
function findEntryRecursive(entries: RecordEntry[], entryID: number): RecordEntry | undefined {
	for(const entry of entries) {
		if(entry.id === entryID) return entry;
		else if(typeof entry.detail === "object") {
			const recursiveResult = findEntryRecursive(entry.detail, entryID);
			if(recursiveResult) return recursiveResult;
		}
	}
	
	return undefined;
}