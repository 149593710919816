import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import languageEN from "./locale/en.json";

//https://react.i18next.com/latest/using-with-hooks
i18next
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources: {
			en: languageEN
		},
		lng: "en",
		fallbackLng: "en",
		debug: true,
		keySeparator: ".",
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		react: {
			wait: true // handle rendering while translations are not yet loaded
		}
	});

export default i18next;