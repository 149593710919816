import React from "react";
import styles from "./Chevron.module.css";

export type ChevronState = "normal" | "groupCollapsed" | "groupExpanded";

// A chevron that can be used in a list
// "normal" represents a gray right-facing chevron
// "group collapsed" represents a colored right-facing chevron
// "group expanded" represents a colored down-facing chevron
export default function Chevron(props: { state: ChevronState}) {
	let className: string;
	switch(props.state) {
		default:
			className = styles.normal;
			break;
		case "groupCollapsed":
			className = styles.group;
			break;
		case "groupExpanded":
			className = styles.group + " " + styles.groupExpanded;
			break;
	}
	
	return (
		<svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path fill="currentColor" d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z"/>
		</svg>
	)
}