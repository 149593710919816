import React, {useEffect, useState} from "react";
import styles from "./EntryList.module.css";

import Toolbar from "../Toolbar";
import {getRecordEntries} from "../../util/dataUtils";
import {useTranslation} from "react-i18next";
import RecordEntry from "../../data/recordEntry";
import {useHistory} from "react-router";
import EntryListItem, {OnSelected} from "./EntryListItem";
import StateHelper, {DataState} from "../StateHelper";
import {CategoryData} from "../../util/categoryDefinitions";

//State of EntryList
//An array of record entries if available, otherwise "loading" or "failed"
type EntryValue = RecordEntry[] | DataState;
export interface ExpansionState {
	isExpanded: boolean;
	children: ExpansionState[];
}

interface HistoryState {
	expansion?: ExpansionState[],
	search?: string,
	scroll?: number
}

//Displays a list of entries to the user in either a tree format or in alphabetical order
export default function EntryList(props: {category: CategoryData}) {
	const history = useHistory();
	const {t} = useTranslation();
	
	const [searchText, setSearchText] = useState<string | undefined>(undefined);
	const [entries, setEntries] = useState<EntryValue>(DataState.Loading);
	useEffect(() => {
		//Reverting the state to loading
		setEntries(DataState.Loading);
		
		//Request data
		const recordEntriesPromise = getRecordEntries();
		if(recordEntriesPromise) {
			recordEntriesPromise.then((result) => {
				const entries = result.get(props.category.id);
				if(entries) {
					//Sort the list first
					//if(props.alphabeticalDisplay) entries.sort((entry1, entry2) => entry1.title.localeCompare(entry2.title));
					setEntries(entries);
				} else {
					setEntries(DataState.Failed);
				}
			}).catch(() => {
				setEntries(DataState.Failed);
			})
		} else {
			//The request failed before we even asked for the result
			setEntries(DataState.Failed);
		}
		
		//Restoring search text
		setSearchText(getHistoryState("search") as string | undefined);
	}, [props.category.id]);
	function updateSearchText(searchText?: string) {
		setSearchText(searchText);
		setHistoryState("search", searchText);
	}
	
	return (
		<div>
			<Toolbar
				button="back"
				title={t(props.category.i18nName)}
				onClickUp={() => history.push("/")}
				actionButton="search"
				searchPlaceholder={t("action.searchHere")}
				searchQuery={searchText}
				onSearch={updateSearchText} />
			<StateHelper state={entries} onReady={(entries) => {
				//Filtering the entries
				const filteredEntries = filterEntries(entries, searchText?.trim()?.toLowerCase());
				
				function onSelected(category: CategoryData, entry: RecordEntry) {
					//Save scroll position
					setHistoryState("scroll", window.scrollY);
					console.log("Saving scroll: " + window.scrollY);
					
					//Navigate to the page
					history.push(`/${category.id}/${entry.id}`);
				}
				
				const listElement = props.category.displayAlphabetical ?
					<ListAlphabetical entries={filteredEntries} category={props.category} onSelected={onSelected} forceExpanded={searchText !== undefined} /> :
					<ListTree entries={filteredEntries} category={props.category} onSelected={onSelected} forceExpanded={searchText !== undefined} />;
				
				return (
					<div className="content content--padding">
						<div className={`card ${styles.list}`}>
							{filteredEntries.length > 0 ?
								listElement :
								<p className={styles.labelNoResults}>{t("message.noResults")}</p>
							}
						</div>
					</div>
				);
			}} />
		</div>
	);
}

function ListTree(props: {entries: RecordEntry[], category: CategoryData, onSelected: OnSelected, forceExpanded?: boolean}) {
	const [expansionState, setExpansionState] = useState<ExpansionState[]>([]);
	
	//Attempt to restore from history state
	useEffect(() => {
		try {
			setExpansionState(getHistoryState("expansion") as ExpansionState[] | undefined ?? []);
		} catch(error) {
			console.warn(error);
		}
	}, []);
	
	useEffect(() => {
		//Restoring the scroll position
		const scroll = getHistoryState("scroll");
		if(scroll) window.scrollTo(0, scroll as number);
	}, [expansionState]);
	
	return (
		<React.Fragment>
			{props.entries.map((entry, index) => (
				<EntryListItem
					key={entry.id}
					category={props.category}
					entry={entry}
					depth={0}
					onSelected={props.onSelected}
					expansionState={expansionState[index]}
					onChangeExpansionState={(state) => {
						//Merge the new state into the total state
						const newState = Object.assign([], expansionState, {[index]: state});
						
						//Update state
						setExpansionState(newState);
						
						//Save to history state
						setHistoryState("expansion", newState);
					}}
					forceExpanded={props.forceExpanded} />
			))}
		</React.Fragment>
	);
}

function ListAlphabetical(props: {entries: RecordEntry[], category: CategoryData, onSelected: OnSelected, forceExpanded?: boolean}) {
	useEffect(() => {
		//Restoring the scroll position
		const scroll = getHistoryState("scroll");
		console.log("Restoring scroll: " + scroll);
		if(scroll) window.scrollTo(0, scroll as number);
	}, []);
	
	return (
		<React.Fragment>
			{Object.entries(groupEntries(props.entries)).map(([firstLetter, entries]) => (
				<React.Fragment key={firstLetter}>
					<p className={styles.sectionHeader}>{firstLetter}</p>
					{entries.map((entry) => (
						<EntryListItem key={entry.id} category={props.category} entry={entry} depth={0} onSelected={props.onSelected} forceExpanded={props.forceExpanded} />
					))}
				</React.Fragment>
			))}
		</React.Fragment>
	);
}

// Recursively searches the entry tree for matching entries
// If an entry's title matches, it will be added to the returned list along with all its children
function filterEntries(srcEntries: RecordEntry[], filter?: string) {
	if(!filter) return srcEntries;
	
	const destEntries: RecordEntry[] = [];
	
	for(const entry of srcEntries) {
		//If this entry's title matches, keep the entire group / entry
		if(entry.title.toLowerCase().includes(filter)) {
			destEntries.push(entry);
		} else if(typeof entry.detail === "object") { //If this entry is a group
			//Filter this children's entries
			const matchingEntries: RecordEntry[] = filterEntries(entry.detail, filter);
			
			//Adding a clone of this entry if there are any matching children
			if(matchingEntries.length > 0) {
				destEntries.push({
					...entry,
					detail: matchingEntries
				})
			}
		}
	}
	
	return destEntries;
}

// Note: This function only checks top-level entries. Any children will remain with their parent.
// Groups entries into an object with keys of their first letter
function groupEntries(entries: RecordEntry[]): {[x: string]: RecordEntry[]} {
	return entries.reduce((accumulator: {[index: string]: RecordEntry[]}, item: RecordEntry) => {
		//Collect entries that start with the same letter
		const firstLetter = item.title.charAt(0).toUpperCase();
		if(!accumulator[firstLetter]) accumulator[firstLetter] = [];
		accumulator[firstLetter].push(item);
		
		return accumulator;
	}, {});
}

function getHistoryState(key: keyof HistoryState) {
	const historyState = window.history.state as HistoryState | undefined;
	if(!historyState) return;
	return historyState[key];
}

function setHistoryState(key: keyof HistoryState, value: HistoryState[keyof HistoryState]) {
	const historyState = window.history.state as HistoryState | undefined;
	const data = Object.assign({}, historyState ?? {}, {[key]: value});
	console.log(data);
	window.history.replaceState(data, "");
}