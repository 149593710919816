import React from "react";
import {useTranslation} from "react-i18next";
import styles from "./list/EntryList.module.css";
import Spinner from "./Spinner";
import iconWarning from "../systemicons/warning.svg";

/*
   This element helps screens handle common states like "loading" and "failed"
   Elements must use the DataState enum to track these states
   and provide a completed child element when onReady is called
 */

export enum DataState {
	Loading,
	Failed
}

export default function StateHelper<T>(props: {state: T | DataState, onReady: (data: T) => JSX.Element}) {
	const {t} = useTranslation();
	
	if(typeof props.state !== "number") {
		return props.onReady(props.state);
	} else if(props.state === DataState.Loading) {
		return (
			<div className={styles.centerContainer}>
				<Spinner />
			</div>
		);
	} else {
		return (
			<div className="centerContainer">
				<img src={iconWarning} alt="" />
				<p>{t("message.loadError")}</p>
			</div>
		);
	}
}