import React from "react";
import styles from "./SearchEntry.module.css";

import {SearchRecordEntry} from "./Search";
import {useHistory} from "react-router";
import Breadcrumbs from "./Breadcrumbs";

// Represents a site-wide search result
export default function SearchEntry(props: {pathedEntry: SearchRecordEntry}) {
	const history = useHistory();
	
	const entry = props.pathedEntry.entry;
	
	if(typeof entry.detail !== "string") {
		console.warn(`Tried to list search result of group entry! (ID ${entry.id}, title ${entry.title}`);
		return null;
	}
	
	function handleClick() {
		//Otherwise, navigate to the page
		history.push(`/${props.pathedEntry.category.id}/${entry.id}`);
	}
	
	return (
		<div className={styles.main} onClick={handleClick}>
			<Breadcrumbs path={props.pathedEntry.path} />
			<span className={styles.title}>{entry.title}</span>
			<span className={styles.description}>{props.pathedEntry.displayDetail}</span>
		</div>
	);
}