import React from "react";
import styles from "./About.module.css";
import {useTranslation} from "react-i18next";
import chevronIcon from "systemicons/chevron_right.svg";
import Toolbar from "../Toolbar";
import {useHistory} from "react-router";

export default function About() {
	const {t} = useTranslation();
	const history = useHistory();
	
	return (
		<div>
			<Toolbar
				button="back"
				title={t("label.about")}
				onClickUp={() => history.push("/")} />
			
			<div className="content content--padding">
				<div className={`card ${styles.main}`}>
					<p className={styles.body}>{t("message.aboutApp")}</p>
					<ButtonLink label={t("label.website")} to="https://premergency.com" />
					<ButtonLink label={t("label.facebook")} to="https://facebook.com/premergencyinc" />
					<ButtonLink label={t("label.twitter")} to="https://twitter.com/Premergency" />
					<ButtonLink label={t("label.linkedin")} to="https://www.linkedin.com/company/premergency" />
				</div>
			</div>
		</div>
	);
}

function ButtonLink(props: {label: string, to: string}) {
	return (
		<a className={styles.buttonLink} href={props.to}>
			<span>{props.label}</span>
			<img className="invertableLight" src={chevronIcon} alt="" />
		</a>
	);
}