import React from "react";
import styles from "./Home.module.css";
import iconMentallyIllPatient from "icons/mentally-ill-patient.svg";
import iconMentalDisorders from "icons/mental-disorders.svg";
import iconPsychologicalFirstAid from "icons/psychological-first-aid.svg";
import iconMedication from "icons/medication.svg";
import iconGlossary from "icons/glossary.svg";

import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import * as Category from "../../util/categoryDefinitions";
import {CategoryData} from "../../util/categoryDefinitions";
import HomeToolbar from "./HomeToolbar";

//The main home screen
export default function Home() {
	return (
		<React.Fragment>
			<HomeToolbar />
			
			<div className="content">
				<div className={styles.listMargin}>
					<TileButtonCategory category={Category.mentallyIllPatient} icon={iconMentallyIllPatient} />
					<TileButtonCategory category={Category.mentalDisorders} icon={iconMentalDisorders} />
					<TileButtonCategory category={Category.psychologicalFirstAid} icon={iconPsychologicalFirstAid} />
					<TileButtonCategory category={Category.medication} icon={iconMedication} />
					<TileButtonCategory category={Category.glossary} icon={iconGlossary} />
				</div>
				
				{/*<Link className={styles.footer} to="/about">{t("label.aboutApp")}</Link>*/}
			</div>
		</React.Fragment>
	);
}

function TileButtonCategory(props: {category: CategoryData, icon: string}) {
	const {t} = useTranslation();
	
	return (
		<Link className={`${styles.tileButton} card`} to={`/${props.category.id}`}>
			<img className={styles.tileButtonIcon} src={props.icon} alt="" />
			<span className={styles.tileButtonLabel}>{t(props.category.i18nName)}</span>
		</Link>
	);
}