import React from "react";
import styles from "./NotFound.module.css";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function NotFound() {
	const {t} = useTranslation();
	
	return (
		<div className={styles.main}>
			<h1>404</h1>
			<p>{t("message.page404")}</p>
			<Link className={styles.footer} to="/">{t("action.returnHome")}</Link>
		</div>
	);
}