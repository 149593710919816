import RecordEntry from "../data/recordEntry";
import {
	strapiDefinitionToRecordEntry,
	strapiEntryToRecordEntry,
	strapiFlatEntryToRecordEntry
} from "./strapiDefinitions";

//Represents a category of data that contains entries
export interface CategoryData {
	id: string; //The locally-used ID of this category
	queryId: string; //The ID of this category as used in the CMS
	querySort: string; //The sorting method for this category to pass to the CMS
	mapper: (item: any) => RecordEntry; //A function to map CMS results to record entries
	i18nName: string; //The I18N key of the title of this category
	displayAlphabetical: boolean; //Whether this category's entries are sorted alphabetically, and they should be grouped by letter
}

export const mentallyIllPatient: CategoryData = {
	id: "mentally-ill-patient",
	queryId: "mentally-ill-patients",
	mapper: strapiEntryToRecordEntry,
	querySort: "sort:ASC",
	i18nName: "category.patient",
	displayAlphabetical: false
};

export const mentalDisorders: CategoryData = {
	id: "mental-disorders",
	queryId: "mental-disorders",
	querySort: "sort:ASC",
	mapper: strapiEntryToRecordEntry,
	i18nName: "category.disorders",
	displayAlphabetical: false
};

export const psychologicalFirstAid: CategoryData = {
	id: "psychological-first-aid",
	queryId: "psychological-first-aids",
	querySort: "sort:ASC",
	mapper: strapiFlatEntryToRecordEntry,
	i18nName: "category.psychological",
	displayAlphabetical: false
};

export const medication: CategoryData = {
	id: "medication",
	queryId: "medications",
	querySort: "title:ASC",
	mapper: strapiDefinitionToRecordEntry,
	i18nName: "category.medication",
	displayAlphabetical: true
};

export const glossary: CategoryData = {
	id: "glossary",
	queryId: "glossaries",
	querySort: "title:ASC",
	mapper: strapiDefinitionToRecordEntry,
	i18nName: "category.glossary",
	displayAlphabetical: true
};

export const allCategories: CategoryData[] = [
	mentallyIllPatient,
	mentalDisorders,
	psychologicalFirstAid,
	medication,
	glossary
];

export function findCategory(id: string): CategoryData | undefined {
	return allCategories.find((category) => category.id === id);
}