import RecordEntry from "../data/recordEntry";
import * as StorageUtils from "./storageUtils";
import * as Category from "./categoryDefinitions";

export const cmsBaseURL = "https://mhapp.premergency.com:8080";

let recordEntryMapPromise: Promise<Map<string, RecordEntry[]>> | undefined = undefined;
let recordEntryMap: Map<string, RecordEntry[]> | undefined = undefined;

export function initializeRecordEntries() {
	function fetchAndApply(foreground: boolean) {
		// Download and apply the data, keeping track of the request under
		// recordEntryMapPromise as long as it's running
		const promise = fetchAllRecords();
		//If this is a foreground request, keep track of the request promise
		if(foreground) recordEntryMapPromise = promise;
		promise.then((result) => {
			//Setting the data in memory
			recordEntryMap = result;
			
			//Updating saved data
			StorageUtils.setRecords(result);
			StorageUtils.updateLastSyncDate();
			
			console.log("Data fetched and saved");
		}).catch((error) => {
			console.warn("Failed to load record entries: " + error);
		}).finally(() => {
			recordEntryMapPromise = undefined;
		});
	}
	
	//Fetching data from storage
	const records = StorageUtils.getRecords();
	if(records) {
		//Applying the records from storage immediately
		recordEntryMap = records;
		
		if(navigator.onLine) {
			console.log("Fetching new data...");
			//Run a background fetch (so that we can display our existing data to the user until we fetch our new data)
			fetchAndApply(false);
		} else {
			console.log("Running offline");
		}
	} else {
		console.log("Fetching data...");
		//Run a foreground fetch (as we don't have anything to display to the user otherwise)
		fetchAndApply(true);
	}
}

export function getRecordEntries(): Promise<Map<string, RecordEntry[]>> | undefined {
	// If we have the record entry available in memory, return it
	// Otherwise, return the promise if we're loading something
	// If there's no promise, a previous load request failed so we'll return undefined
	if(recordEntryMap) return Promise.resolve(recordEntryMap);
	else if(recordEntryMapPromise) return recordEntryMapPromise;
	else return undefined;
}

async function fetchAllRecords(): Promise<Map<string, RecordEntry[]>> {
	const map: Map<string, RecordEntry[]> = new Map();
	
	//Fetching records for each data code
	for(const category of Category.allCategories) {
		//Fetching records
		map.set(category.id, await fetchRecords(`${cmsBaseURL}/${category.queryId}?_sort=${category.querySort}`, category.mapper));
	}
	
	return map;
}

// Fetches a list of records from the specified URL
async function fetchRecords(url: string, mapper: (item: any) => RecordEntry): Promise<RecordEntry[]> {
	//Fetching the data
	const response = await asyncJsonRequest(url);
	
	//Mapping each Strapi item to a record item and returning
	return (response as any[]).map(mapper);
}

async function asyncJsonRequest(url: string): Promise<any> {
	return new Promise<any>((resolve, reject) => {
		//Querying the URL
		const xhr = new XMLHttpRequest();
		xhr.responseType = "json";
		xhr.open("GET", url);
		xhr.send();
		xhr.onload = () => {
			if(xhr.status !== 200) {
				reject(`XMLHttpRequest error: ${xhr.status}`);
			}
			resolve(xhr.response);
		}
		xhr.onerror = () => {
			reject(`XMLHttpRequest error: ${xhr.status}`);
		}
	});
}