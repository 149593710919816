import React from "react";
import {BrowserRouter, Route, Switch, useParams} from "react-router-dom";
import Home from "./home/Home";
import EntryList from "./list/EntryList";
import EntryViewer from "./viewer/EntryViewer";
import Search from "./search/Search";
import About from "./about/About";
import {findCategory} from "../util/categoryDefinitions";
import NotFound from "./not_found/NotFound";

export default function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/search">
					<Search />
				</Route>
				<Route path="/about">
					<About />
				</Route>
				<Route path="/:categoryID/:entryID">
					<Viewer />
				</Route>
				<Route path="/:categoryID">
					<Category />
				</Route>
				<Route path="/">
					<Home />
				</Route>
			</Switch>
		</BrowserRouter>
	);
}

function Viewer() {
	const {categoryID, entryID} = useParams<{categoryID: string, entryID: string}>();
	const category = findCategory(categoryID);
	const entryIDNum = parseInt(entryID);
	
	if(category && !isNaN(entryIDNum)) {
		return <EntryViewer category={category} entryID={entryIDNum} />;
	} else {
		return <NotFound />;
	}
}

function Category() {
	const {categoryID} = useParams<{categoryID: string}>();
	const category = findCategory(categoryID);
	
	if(category) {
		return <EntryList category={category} />;
	} else {
		return <NotFound />;
	}
}