import React from "react";
import styles from "./Breadcrumbs.module.css";

import iconChevron from "systemicons/chevron_right.svg";

export default function Breadcrumbs(props: {path: string[]}) {
	return (
		<div className={styles.main}>
			{props.path.map((path, index) => (
				<React.Fragment key={`${index}-${path}`}>
					{index > 0 && <img className="invertableLight" src={iconChevron} alt="" />}
					<span>{path}</span>
				</React.Fragment>
			))}
		</div>
	);
}