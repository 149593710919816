//Object shapes returned from Strapi CMS

import RecordEntry, {RecordEntryAttachment} from "../data/recordEntry";
import {cmsBaseURL} from "./dataUtils";

export interface StrapiEntry {
	id: number;
	title: string;
	sort: number;
	entries: StrapiEntryChild[];
}

export interface StrapiEntryChild {
	__component: string;
	id: number;
	title: string;
}

export interface StrapiEntryDocument extends StrapiEntryChild {
	__component: "document.document";
	body: string;
	attachment: [];
}

export interface StrapiEntryGroup extends StrapiEntryChild {
	__component: "document.document-child";
	documents: StrapiEntryDocument[];
}

function isStrapiEntryChildDocument(entry: StrapiEntryChild): entry is StrapiEntryDocument {
	return entry.__component === "document.document";
}

function isStrapiEntryChildGroup(entry: StrapiEntryChild): entry is StrapiEntryGroup {
	return entry.__component === "document.document-child";
}

export interface StrapiEntryFlat {
	id: number;
	title: string;
	body: string;
	attachment: StrapiAttachment[];
}

export interface StrapiDefinition {
	id: number;
	title: string;
	body: string;
}

export interface StrapiAttachment {
	id: number;
	name: string;
	alternativeText: string;
	caption: string;
	width: number | null;
	height: number | null;
	formats: {[x: string]: StrapiAttachmentFormat}
	hash: string;
	ext: string;
	mime: string;
	size: number;
	url: string;
	previewUrl: string;
}

export interface StrapiAttachmentFormat {
	name: string;
	hash: string;
	ext: string;
	mime: string;
	width: number;
	height: number;
	size: number;
	url: string;
}

export function strapiAttachmentToRecordEntryAttachment(attachment: StrapiAttachment): RecordEntryAttachment {
	return {
		id: attachment.hash,
		name: attachment.name,
		mime: attachment.mime,
		url: cmsBaseURL + attachment.url,
		alt: attachment.alternativeText
	};
}

export function strapiEntryToRecordEntry(entry: StrapiEntry): RecordEntry {
	//Map a Strapi child to a record entry
	function strapiEntryChildToRecordEntry(entry: StrapiEntryChild): RecordEntry {
		//Map a Strapi child document to a record entry
		function strapiEntryDocumentToRecordEntry(entry: StrapiEntryDocument): RecordEntry {
			return {
				id: entry.id,
				title: entry.title,
				detail: entry.body,
				attachments: entry.attachment.map(strapiAttachmentToRecordEntryAttachment)
			};
		}
		
		if(isStrapiEntryChildDocument(entry)) {
			return strapiEntryDocumentToRecordEntry(entry);
		} else if(isStrapiEntryChildGroup(entry)) {
			return {
				id: entry.id,
				title: entry.title,
				detail: entry.documents.map(strapiEntryDocumentToRecordEntry)
			};
		} else {
			throw new Error("Unknown Strapi child type: " + JSON.stringify(entry));
		}
	}
	
	//The top-level entry will always be a group
	return {
		id: entry.id,
		title: entry.title,
		detail: entry.entries.map(strapiEntryChildToRecordEntry)
	};
}

export function strapiFlatEntryToRecordEntry(entry: StrapiEntryFlat): RecordEntry {
	return {
		id: entry.id,
		title: entry.title,
		detail: entry.body,
		attachments: entry.attachment.map(strapiAttachmentToRecordEntryAttachment)
	}
}

//Map a Strapi definition entry to a record entry
export function strapiDefinitionToRecordEntry(definition: StrapiDefinition): RecordEntry {
	return {
		id: definition.id,
		title: definition.title,
		detail: definition.body
	}
}