import RecordEntry from "../data/recordEntry";

const keyLastSyncDate = "lastSync";
const keyRecords = "records";

// Get the last sync date from local storage,
// defaulting to January 1st, 2000 if none is available
export function getLastSyncDate(): Date {
	const dateString = localStorage.getItem(keyLastSyncDate);
	if(dateString) return new Date(dateString);
	else return new Date(2000, 0);
}

export function updateLastSyncDate() {
	localStorage.setItem(keyLastSyncDate, new Date().toString());
}

export function getRecords(): Map<string, RecordEntry[]> | undefined {
	const mapString = localStorage.getItem(keyRecords);
	if(!mapString) return undefined;
	return new Map(JSON.parse(mapString));
}

export function setRecords(records: Map<string, RecordEntry[]>) {
	localStorage.setItem(keyRecords, JSON.stringify([...records]));
}