import React from "react";
import styles from "./EntryListItem.module.css";

import RecordEntry from "../../data/recordEntry";
import Chevron, {ChevronState} from "./Chevron";
import {CategoryData} from "../../util/categoryDefinitions";
import {ExpansionState} from "./EntryList";

export type OnSelected = (category: CategoryData, entry: RecordEntry) => void;

interface Props {
	category: CategoryData;
	entry: RecordEntry;
	depth: number;
	onSelected: OnSelected;
	expansionState?: ExpansionState;
	onChangeExpansionState?: (expansionState: ExpansionState) => void;
	forceExpanded?: boolean;
}

// Represents a single entry in a list
export default function EntryListItem(props: Props) {
	//True if this entry represents a group of entries
	const isGroup = typeof props.entry.detail === "object";
	
	const isExpanded = isGroup && (props.expansionState?.isExpanded || props.forceExpanded);
	
	let chevronType: ChevronState;
	if(typeof props.entry.detail === "string") chevronType = "normal";
	else chevronType = isExpanded ? "groupExpanded" : "groupCollapsed";
	
	function handleClick() {
		if(isGroup) {
			if(!props.forceExpanded) {
				//Toggling the expanded state if this is a group
				props.onChangeExpansionState!(props.expansionState ? {
					isExpanded: !props.expansionState.isExpanded,
					children: props.expansionState.children
				} : {
					isExpanded: true,
					children: []
				});
			}
		} else {
			//Otherwise, navigate to the page
			props.onSelected(props.category, props.entry);
		}
	}
	
	return (
		<div className={props.depth === 0 ? styles.main : styles.mainInset}>
			<div className={styles.container} onClick={handleClick}>
				<span className={props.depth === 0 ? styles.textPrimary : styles.textSecondary}>{props.entry.title}</span>
				<Chevron state={chevronType} />
			</div>
			{isExpanded &&
				<div className={styles.childrenContainer} style={{marginLeft: 16}}>
					{(props.entry.detail as RecordEntry[]).map((entry, index) => (
						<EntryListItem
							key={entry.id}
							category={props.category}
							entry={entry}
							depth={props.depth + 1}
							onSelected={props.onSelected}
							expansionState={props.expansionState?.children?.[index]}
							onChangeExpansionState={(state) => {
								//Merge the state into the children array
								props.onChangeExpansionState!({
									isExpanded: props.expansionState?.isExpanded!!,
									children: Object.assign([], props.expansionState?.children ?? [], {[index]: state})
								});
							}}
							forceExpanded={props.forceExpanded} />
					))}
				</div>
			}
		</div>
	);
}