import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import {initializeRecordEntries} from "./util/dataUtils";
import {Capacitor, Plugins, StatusBarStyle} from "@capacitor/core";

//Loading data
initializeRecordEntries();

if(Capacitor.platform === "ios") {
	//iOS light status bar
	Plugins.StatusBar.setStyle({
		style: StatusBarStyle.Dark
	});
}

//Disable scroll restoration
if(window.history.scrollRestoration) {
	window.history.scrollRestoration = "manual";
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();